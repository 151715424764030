* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f2f5f6;
}

code, pre {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body {
  height: calc(100% - 32px);
}

body {
  --container-width: 750px;
}

#root {
  height: 100%;
}

a, a:visited {
  text-decoration: none;
  color: unset;
}

::-webkit-scrollbar {
  width: 0;
}

@media (min-width: 768px) {
  body {
    --container-width: 1400px;
  }
}
